@import './scss/fonts/_index.scss';
@import './scss/tailwind.scss';
@import './scss/base/_index.scss';
@import './scss/components/_index.scss';
@import './scss/utilities/_index.scss';
@import './scss/custom/_index.scss';

#__next {
  @apply absolute inset-0 overflow-hidden;
}
