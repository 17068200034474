* {
  box-sizing: border-box;
}

//body {
//  margin: 0;
//  //font-family: -apple-system, BlinkMacSystemFont, 'Rubik', 'Segoe UI', 'Roboto', 'Oxygen',
//  //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//  //sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

html,
body {
  height: 100%;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.canvas {
  position: relative;
  width: auto;
  height: 100%;
  flex: 1 1 auto;
  outline: none;
  background-color: lightgray;
}

.canvas canvas {
  width: 100% !important;
  height: 100% !important;
}

/* DEV ONLY */
#root .leva-c-kWgxhW {
  right: 300px;
  top: 10px;
}

.scene-annotation {
  background-color: rgba(255, 255, 255, .3);
  color: black;
  pointer-events: none;
  user-select: none;
}
